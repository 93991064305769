/*
  import { vendorRequestStore } from '@/services/VendorRequests/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async addVendorRequestWithAddress({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in addVendorRequestWithAddress ')
    try {
      const params = {
        hoaID: payload.hoaID,
        vendorServiceTypeID: payload.vendorServiceTypeID,
        vendorName: payload.vendorName,
        email: payload.email,
        phone: payload.phone,
        workDescription: payload.workDescription,
        address: {
          addressOne: payload.address,
          city: payload.city,
          state: payload.state,
          postalCode: payload.postalCode
        }
      }
      const results = await kms.post(`/VendorRequests/VendorRequest`, params)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.vendorRequestID > 0) {
        notifyMessage(`Successfully created your vendor request.`)
        done({ details: results.vendorRequestID })
      } else {
        notifyError('There was a problem creating this vendor request.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem creating this vendor request.`)
      console.error(exception)
    }
  },

  async getVendorRequestById({}, { vendorRequestID }) {
    try {
      console.debug('in getVendorRequestById...')
      const result = await kms.get(`/VendorRequests/VendorRequest/${vendorRequestID}`)

      if (isDebug == true) console.debug('getVendorRequestById=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the vendor request.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async getVendorRequestList({}, { hoaID }) {
    console.log('in getVendorRequestList...')
    try {
      const results = await kms.get('/VendorRequests/VendorRequest/List', {
        params: {
          hoaID
        }
      })

      if (isDebug == true) console.debug('getVendorRequestList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The vendor request list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async deleteVendorRequest({ dispatch }, { vendorRequestID, done }) {
    try {
      const path = `/VendorRequests/VendorRequest/${vendorRequestID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted this vendor request.`)
        done()
      } else {
        notifyError('There was a problem deleting this vendor request.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this Vendor Request.`)
      console.error(exception)
    }
  },

  async updateVendorRequest({ dispatch }, { payload = {}, done }) {
    try {
      if (isDebug === true) console.debug('updateVendorRequest payload=' + JSON.stringify(payload))

      const results = await kms.put(`/VendorRequests/VendorRequest`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.vendorRequestID > 0) {
        notifyMessage(`Successfully updated the vendor request.`)
        done({ details: results.vendorRequestID })
      } else {
        notifyError('There was a problem updating this vendor request.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this vendor request.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const vendorRequestStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
