<template>
  <div>
    <div class="is-size-4" v-if="formData">Create Vendor Request</div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <fieldset>
            <div class="columns">
              <div class="column is-6">
                <valid-input
                  style="width:50px !important;"
                  name="VendorName"
                  label="Vendor Name"
                  type="text"
                  vid="VendorName"
                  placeholder="Vendor Name"
                  spellcheck="true"
                  aria-label="VendorName"
                  rules="required|max:100"
                  class="is-small"
                  v-model="formData.vendorName"
                />
              </div>
              <div class="column is-6">
                <valid-input
                  style="width:50px !important;"
                  name="Address"
                  label="Address"
                  type="text"
                  vid="Address"
                  placeholder="Address"
                  spellcheck="true"
                  aria-label="Address"
                  rules="required|max:100"
                  class="is-small"
                  v-model="formData.address"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-6">
                <valid-input
                  style="width:50px !important;"
                  name="Email"
                  label="Email"
                  type="text"
                  vid="Email"
                  placeholder="Email"
                  spellcheck="true"
                  aria-label="Email"
                  rules="required|max:100"
                  class="is-small"
                  v-model="formData.email"
                />
              </div>
              <div class="column is-6">
                <valid-input
                  style="width:50px !important;"
                  name="City"
                  label="City"
                  type="text"
                  vid="City"
                  placeholder="City"
                  spellcheck="true"
                  aria-label="City"
                  rules="required|max:100"
                  class="is-small"
                  v-model="formData.city"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-6">
                <valid-input
                  style="width:50px !important;"
                  name="Phone"
                  label="Phone"
                  type="text"
                  vid="Phone"
                  placeholder="Phone"
                  aria-label="Phone"
                  rules="required|max:100"
                  class="is-small"
                  v-model="formData.phone"
                />
              </div>
              <div class="column is-6" v-if="statesList">
                <b-field label="State">
                  <b-select v-model="formData.state" required="true" placeholder="Select a State">
                    <option v-for="option in statesList" :value="option.value" :key="option.value">
                      {{ option.label }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-6">
                <valid-input
                  style="width:50px !important;"
                  name="Work Description"
                  label="Work Description"
                  type="textarea"
                  vid="WorkDescription"
                  placeholder="Work Description"
                  aria-label="Work Description"
                  rules="required|max:8000"
                  class="is-small"
                  v-model="formData.workDescription"
                />
              </div>
              <div class="column is-6">
                <valid-input
                  style="width:50px !important;"
                  name="PostalCode"
                  label="Postal Code"
                  type="text"
                  vid="PostalCode"
                  placeholder="PostalCode"
                  aria-label="Postal Code"
                  rules="required|max:100"
                  class="is-small"
                  v-model="formData.postalCode"
                />
              </div>
            </div>
            <div class="columns"></div>
            <div class="columns">
              <div class="column is-6">
                <b-field label="Service Type">
                  <b-select
                    v-model="formData.vendorServiceTypeID"
                    required="true"
                    placeholder="Select a Service Type"
                  >
                    <option
                      v-for="option in serviceTypes"
                      :value="option.value"
                      :key="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>

            <label class="label">Documents</label>
            <PDFUploader :updateV2="documentsUpdated" :multiple="true" />

            <PhotoUploader
              class="pt-5"
              ref="photoUploader"
              label="Photos"
              :update="adderPhotosUpdated"
            />

            <div class="column is-12">
              <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                Create
              </button>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { mapState, mapGetters } from 'vuex'
import $ from 'jquery'
import { notifyError } from '@/services/notify'

// components
import ValidInput from '@/components/inputs/ValidInput'

import _get from 'lodash/get'
import moment from 'moment'
import { vendorServiceTypeStore } from '@/services/VendorServiceType/store'
import { vendorRequestStore } from '@/services/VendorRequests/store'
import { vendorRequestDocumentStore } from '@/services/VendorRequestDocuments/store'
import PDFUploader from '@/components/Fields/PDFUploader'
import PhotoUploader from '@/components/Fields/PhotoUploader'

let DEFAULT_FORM_VALUES = {
  vendorName: null,
  email: '',
  phone: '',
  workDescription: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  vendorServiceTypeID: null
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'VendorRequestModal',

  props: {
    workOrderRequest: Object,
    hidePromoteModal: String
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    PDFUploader,
    PhotoUploader
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      serviceTypes: [],
      selectedRow: null,
      buttonText: '',
      openOnFocus: true,
      isFetching: false,
      data: [],
      files: [],
      photos: [],
      image: null,
      doc: null,
      documentIds: [],
      previews: [],
      docPreviews: [],
      documents: [],
      adder: {
        photos: [],
        name: ''
      }
    }
  },

  async created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapGetters('unit', ['statesList'])
  },

  async mounted() {
    this.loading = true

    this.$store.dispatch('unit/getStatesList', {
      useAbbreviated: true
    })

    if (this.hidePromoteModal && this.hidePromoteModal === 'true') {
      if (this.isDebug === true) console.debug('hidePromoteModal=' + this.hidePromoteModal)

      this.hideMap = true
    }

    this.formData = { ...DEFAULT_FORM_VALUES }

    await this.loadServiceTypes()

    this.loading = false
  },

  methods: {
    documentsUpdated({ documents }) {
      this.documents = documents
    },

    async adderPhotosUpdated({ items }) {
      this.adder.photos = items
    },

    async loadServiceTypes() {
      await vendorServiceTypeStore.dispatch('getVendorServiceTypeList').then(({ list }) => {
        if (list) {
          console.log('list=' + JSON.stringify(list))
          this.serviceTypes = list.map(m => ({
            value: m.vendorServiceTypeID,
            label: m.name
          }))
        }
      })
    },

    getPayload() {
      const payload = {
        hoaID: this.hoaId,
        vendorName: _get(this.formData, 'vendorName', ''),
        email: _get(this.formData, 'email', ''),
        phone: _get(this.formData, 'phone', ''),
        workDescription: _get(this.formData, 'workDescription', ''),
        address: _get(this.formData, 'address', ''),
        city: _get(this.formData, 'city', ''),
        state: _get(this.formData, 'state', ''),
        postalCode: _get(this.formData, 'postalCode', ''),
        vendorServiceTypeID: _get(this.formData, 'vendorServiceTypeID', 0)
      }

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      return payload
    },

    async onFormSubmit() {
      console.debug('in onFormSubmit...')
      if (!this.formData || this.formData == undefined) {
        notifyError('There was a problem submitting your vendor request.')
        return
      }

      console.debug('formData=' + JSON.stringify(this.formData))

      try {
        const payload = this.getPayload()
        let validationMessages = ''

        if (payload.vendorServiceTypeID == 0) {
          validationMessages = '<div>Please select a service type.</div>'
        }

        if (payload.vendorName == '') {
          validationMessages = '<div>Please enter a vendor name.</div>'
        }

        if (payload.email == '') {
          validationMessages = '<div>Please enter an email address.</div>'
        }

        if (payload.phone == '') {
          validationMessages = '<div>Please enter a phone number.</div>'
        }

        if (payload.workDescription == '') {
          validationMessages = '<div>Please enter a work description.</div>'
        }

        if (payload.address == '') {
          validationMessages = '<div>Please enter an address.</div>'
        }

        if (payload.city == '') {
          validationMessages = '<div>Please enter a city.</div>'
        }

        if (payload.state == '') {
          validationMessages = '<div>Please select a state.</div>'
        }

        if (payload.postalCode == '') {
          validationMessages = '<div>Please enter a postal code.</div>'
        }

        if (validationMessages != '') {
          notifyError(validationMessages)
          return
        }

        this.loading = true

        await vendorRequestStore.dispatch('addVendorRequestWithAddress', {
          payload,
          done: async ({ details }) => {
            console.debug('details=' + JSON.stringify(details))

            //save attachments
            if (details) {
              console.debug('calling saveAttachments...')
              await this.saveAttachments(details)
            }

            // auto-close modal
            this.$emit('update:toggle', (this.toggle = false))
            $('#clickclose').click()
            this.loading = false
          }
        })
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem saving this vendor request.' + e)
      }
      this.loading = false
    },

    async deleteDropFile(index) {
      this.files.splice(index, 1)

      const attachments = this.files

      const previews = this.previews
      this.previews.splice(index, 1)

      this.$store.dispatch('conversation/modify', {
        previews,
        attachments
      })
    },

    async saveAttachments(id) {
      //Process one or more attachments
      try {
        this.files = [...this.adder.photos, ...this.documents]

        if (this.isDebug === true) console.debug('files=' + this.files)

        if (this.files && this.files.length > 0) {
          for (let a = 0; a < this.files.length; a++) {
            const payload = {
              file: this.files[a],
              description: this.files[a].name || '',
              hoaID: this.hoaId
            }

            let documentID = 0
            await vendorRequestDocumentStore
              .dispatch('uploadVendorRequestDocument', {
                payload: payload
              })
              .then(({ returnedfile }) => {
                console.debug('in uploadVendorRequestDocument=' + JSON.stringify(returnedfile))
                this.base64pdf = ''
                if (returnedfile && returnedfile !== undefined) {
                  documentID = returnedfile.documentId
                } else {
                  console.debug('There was a problem uploading your attachments.')
                  return
                }
              })

            if (documentID > 0) {
              await vendorRequestDocumentStore
                .dispatch('addVendorRequestDocument', {
                  vendorRequestID: id,
                  documentID,
                  description: payload.description
                })
                .then(async ({ returned }) => {
                  if (returned && returned != undefined && returned.vendorRequestDocumentID > 0) {
                    return
                  }
                })
            }
          }
        }
      } catch (e) {
        notifyError(e)
      }
    }
  }
}
</script>

<style lang="scss">
#vendor-request-modal .my-grid-class {
  height: 400px;
}

#vendor-request-modal tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
