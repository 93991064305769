<template>
  <PageContentLayoutOnly>
    <ModernTable
      :filters="filters"
      :rows="documentsList"
      :columns="columns"
      :pagination="pagination"
    >
      <template v-slot:header>
        <div class="pb-5">
          <b-button @click="createDocumentModal()" size="is-small" type="is-primary" rounded>
            Attach Document
          </b-button>
          <b-button
            type="is-ghost"
            @click="toggleFilters"
            :style="styles.filterButton"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
    <Modal :toggle.sync="toggle">
      <template v-slot:header>Vendor Request Attachment</template>
      <DocumentsModal :vendorRequestID="parseInt(vendorRequestID)" :attachment="selectedDocument" />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import DocumentsModal from '@/components/Modals/VendorRequestAttachmentModal'
import Modal from '@/components/Modal'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'VendorRequestAttachments',

  components: {
    PageContentLayoutOnly,
    ModernTable,
    DocumentsModal,
    Modal
  },

  data,
  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { vendorRequest: { title: 'Documents' } }
    }
  }
}
</script>

<style></style>
