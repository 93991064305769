<template>
  <PageContentLayoutOnly role="region">
    <div v-if="vendorFilter === 'requests'" class="title">{{ $t('vendor.title2') }}</div>
    <div v-else class="title">{{ $t('vendor.title') }}</div>
    <div class="level">
      <div v-if="vendorFilter === 'requests'" class="level-left">
        <b-button
          @click.prevent="createVendorRequestModal()"
          type="is-primary"
          rounded
          :style="{
            ...(breakpoint === 'desktop'
              ? {}
              : {
                  width: '100%'
                })
          }"
        >
          Add Vendor Request
        </b-button>
      </div>
      <div
        v-if="vendorFilter === 'vendors'"
        class="level-left level-item pb-3"
        :style="{ maxWidth: '80%' }"
      >
        <b-notification type="is-info is-light" has-icon aria-close-label="Close notification">
          To request that a new vendor be added to the HOA, click on the Vendor Requests tab and
          then click 'Add Vendor Request'. To assist us in the vendor setup process, enter as much
          of the requested information about the vendor as possible. We will review the provided
          information and inform you when the new vendor is available.
        </b-notification>
      </div>
      <div class="level-right level-item pb-5">
        <b-field label="Vendor Filter" tabindex="0" aria-label="vendor / vendor request filter">
          <b-radio
            v-model="vendorFilter"
            name="name"
            native-value="vendors"
            aria-label="only show vendors"
          >
            Vendors
          </b-radio>
          <b-radio
            v-model="vendorFilter"
            name="name"
            native-value="requests"
            aria-label="only show vendor requests"
          >
            Vendor Requests
          </b-radio>
        </b-field>
      </div>
    </div>
    <span v-if="vendorFilter === 'requests'">
      <ModernTable
        :region="region2"
        :filters="filters"
        :rows="rows2"
        :columns="columns2"
        :pagination="{
          perPage: 12
        }"
        :loading="loading"
        headerColor="#FFF"
        condensed
      >
        <template v-slot:header>
          <div :style="{ width: '100%', height: '60px' }">
            <b-button
              type="is-ghost"
              @click="filters.show = !filters.show"
              :style="{ float: 'right', background: 'none', border: 0 }"
              :class="'filter-button ' + (filters.show ? 'active' : '')"
            >
              <b-icon
                pack="mdi"
                icon="filter-outline"
                size="is-small"
                style="display: inline-block"
              >
              </b-icon>
              <div style="display: inline-block">Filter</div>
            </b-button>
          </div>
        </template>
      </ModernTable>
    </span>
    <span v-else>
      <ModernTable
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 12
        }"
        :loading="loading"
        headerColor="#FFF"
        condensed
      >
        <template v-slot:header>
          <div :style="{ width: '100%', height: '60px' }">
            <b-button
              type="is-ghost"
              @click="filters.show = !filters.show"
              :style="{ float: 'right', background: 'none', border: 0 }"
              :class="'filter-button ' + (filters.show ? 'active' : '')"
            >
              <b-icon
                pack="mdi"
                icon="filter-outline"
                size="is-small"
                style="display: inline-block"
              >
              </b-icon>
              <div style="display: inline-block">Filter</div>
            </b-button>
          </div>
        </template>
      </ModernTable>
    </span>
    <Modal :toggle.sync="toggle">
      <VendorRequestModal />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { methods } from './keys/methods'
import { data } from './keys/data'
import VendorRequestModal from '@/components/VendorRequestModal'
import Modal from '@/components/Modal'

export default {
  name: 'Vendors',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    VendorRequestModal,
    Modal
  },

  data,

  methods,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser']),
      breakpoint: state => (state && state.win ? state.win.breakpoint : null)
    })
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.loadVendorRequests()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },
    vendorFilter() {
      if (this.vendorFilter === 'vendors') {
        this.reload()
      } else if (this.vendorFilter === 'requests') {
        this.loadVendorRequests()
      }
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: {
        vendor: {
          title: 'Vendors',
          title2: 'Vendor Requests'
        }
      }
    }
  }
}
</script>

<style></style>
