import { vendorServiceTypeStore } from '@/services/VendorServiceType/store'
import { vendorRequestStore } from '@/services/VendorRequests/store'
import _get from 'lodash/get'
import { notifyError } from '@/services/notify'
import { ValidationError } from '@/services/errors'

var DEFAULT_FORM_VALUES = {
  vendorRequestID: 0,
  vendorName: null,
  email: '',
  phone: '',
  workDescription: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  vendorServiceTypeID: null
}

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async reload() {
    this.loading = true

    console.debug('route values=' + JSON.stringify(this.$route.params))

    this.vendorRequestID = this.$route.params.id

    this.formData = { ...DEFAULT_FORM_VALUES }

    await this.loadVendorRequestByID()

    await this.loadServiceTypes()

    if (this.vendorRequestDetail) {
      try {
        this.formData.vendorRequestID = _get(this.vendorRequestDetail, 'vendorRequestID', 0)
        this.formData.vendorName = _get(this.vendorRequestDetail, 'vendorName', '')
        this.formData.email = _get(this.vendorRequestDetail, 'email', '')
        this.formData.phone = _get(this.vendorRequestDetail, 'phone', '')
        this.formData.workDescription = _get(this.vendorRequestDetail, 'workDescription', '')
        this.formData.address = _get(this.vendorRequestDetail.address, 'addressOne', '')
        this.formData.city = _get(this.vendorRequestDetail.address, 'city', '')
        this.formData.state = _get(this.vendorRequestDetail.address, 'state', '')
        this.formData.postalCode = _get(this.vendorRequestDetail.address, 'postalCode', '')
        this.formData.vendorServiceTypeID = _get(this.vendorRequestDetail, 'vendorServiceTypeID', 0)
      } catch (e) {
        console.debug('error while loading the vendor request detail data:' + e)
      }
    }

    this.loading = false
  },

  getPayload() {
    const payload = {
      vendorRequestID: _get(this.formData, 'vendorRequestID', 0),
      hoaID: this.hoaId,
      vendorName: _get(this.formData, 'vendorName', ''),
      email: _get(this.formData, 'email', ''),
      phone: _get(this.formData, 'phone', ''),
      workDescription: _get(this.formData, 'workDescription', ''),
      vendorServiceTypeID: _get(this.formData, 'vendorServiceTypeID', 0),
      address: {
        addressID: _get(this.vendorRequestDetail.address, 'addressID', 0),
        addressOne: _get(this.formData, 'address', ''),
        city: _get(this.formData, 'city', ''),
        state: _get(this.formData, 'state', ''),
        postalCode: _get(this.formData, 'postalCode', '')
      }
    }

    if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

    return payload
  },

  async onFormSubmit() {
    console.debug('in onFormSubmit...')
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem submitting your vendor request.')
      return
    }

    console.debug('formData=' + JSON.stringify(this.formData))

    try {
      const payload = this.getPayload()
      let validationMessages = ''

      if (payload.vendorRequestID == 0) {
        validationMessages = '<div>Please supply a vendor request ID.</div>'
      }

      if (payload.vendorServiceTypeID == 0) {
        validationMessages = '<div>Please select a service type.</div>'
      }

      if (payload.vendorName == '') {
        validationMessages = '<div>Please enter a vendor name.</div>'
      }

      if (payload.email == '') {
        validationMessages = '<div>Please enter an email address.</div>'
      }

      if (payload.phone == '') {
        validationMessages = '<div>Please enter a phone number.</div>'
      }

      if (payload.workDescription == '') {
        validationMessages = '<div>Please enter a work description.</div>'
      }

      if (payload.address == '') {
        validationMessages = '<div>Please enter an address.</div>'
      }

      if (payload.city == '') {
        validationMessages = '<div>Please enter a city.</div>'
      }

      if (payload.state == '') {
        validationMessages = '<div>Please select a state.</div>'
      }

      if (payload.postalCode == '') {
        validationMessages = '<div>Please enter a postal code.</div>'
      }

      if (validationMessages != '') {
        notifyError(validationMessages)
        return
      }

      this.loading = true

      await vendorRequestStore.dispatch('updateVendorRequest', {
        payload,
        done: ({ details }) => {
          if (details) {
            this.loadVendorRequestByID()
          }
        }
      })
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      notifyError('There was a problem saving this vendor request.' + e)
    }
    this.loading = false
  },

  async loadVendorRequestByID() {
    await vendorRequestStore
      .dispatch('getVendorRequestById', {
        vendorRequestID: this.vendorRequestID
      })
      .then(({ result }) => {
        if (result) {
          this.vendorRequestDetail = result
          this.vendorName = result.vendorName || ''
        }
      })
  },

  async loadServiceTypes() {
    await vendorServiceTypeStore.dispatch('getVendorServiceTypeList').then(({ list }) => {
      if (list) {
        console.log('list=' + JSON.stringify(list))
        this.serviceTypes = list.map(m => ({
          value: m.vendorServiceTypeID,
          label: m.name
        }))
      }
    })
  }
}
