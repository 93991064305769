<template>
  <PageContentLayoutOnly headerColor="#fafafa">
    <div class="subtitle">{{ vendorName }}</div>
    <div itemdetails class="notification headerColor" v-if="formData">
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="VendorName"
                      label="Vendor Name"
                      type="text"
                      vid="VendorName"
                      placeholder="Vendor Name"
                      spellcheck="true"
                      aria-label="VendorName"
                      rules="required|max:100"
                      class="is-small"
                      v-model="formData.vendorName"
                    />
                  </div>
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="Address"
                      label="Address"
                      type="text"
                      vid="Address"
                      placeholder="Address"
                      spellcheck="true"
                      aria-label="Address"
                      rules="required|max:100"
                      class="is-small"
                      v-model="formData.address"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="Email"
                      label="Email"
                      type="text"
                      vid="Email"
                      placeholder="Email"
                      spellcheck="true"
                      aria-label="Email"
                      rules="required|max:100"
                      class="is-small"
                      v-model="formData.email"
                    />
                  </div>
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="City"
                      label="City"
                      type="text"
                      vid="City"
                      placeholder="City"
                      spellcheck="true"
                      aria-label="City"
                      rules="required|max:100"
                      class="is-small"
                      v-model="formData.city"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="Phone"
                      label="Phone"
                      type="text"
                      vid="Phone"
                      placeholder="Phone"
                      aria-label="Phone"
                      rules="required|max:100"
                      class="is-small"
                      v-model="formData.phone"
                    />
                  </div>
                  <div class="column is-6" v-if="statesList">
                    <b-field label="State">
                      <b-select
                        v-model="formData.state"
                        required="true"
                        placeholder="Select a State"
                      >
                        <option
                          v-for="option in statesList"
                          :value="option.value"
                          :key="option.value"
                        >
                          {{ option.label }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="Work Description"
                      label="Work Description"
                      type="textarea"
                      vid="WorkDescription"
                      placeholder="Work Description"
                      aria-label="Work Description"
                      rules="required|max:8000"
                      class="is-small"
                      v-model="formData.workDescription"
                    />
                  </div>
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="PostalCode"
                      label="Postal Code"
                      type="text"
                      vid="PostalCode"
                      placeholder="PostalCode"
                      aria-label="Postal Code"
                      rules="required|max:100"
                      class="is-small"
                      v-model="formData.postalCode"
                    />
                  </div>
                </div>
                <div class="columns"></div>
                <div class="columns">
                  <div class="column is-6">
                    <b-field label="Service Type">
                      <b-select
                        v-model="formData.vendorServiceTypeID"
                        required="true"
                        placeholder="Select a Service Type"
                      >
                        <option
                          v-for="option in serviceTypes"
                          :value="option.value"
                          :key="option.value"
                        >
                          {{ option.label }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                </div>
                <div class="column is-12">
                  <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                    Save
                  </button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'

// components
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'VendorRequestDetails',
  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    PageContentLayoutOnly
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    }),
    ...mapGetters('unit', ['statesList'])
  },

  created() {},

  mounted() {
    this.$store.dispatch('unit/getStatesList', {
      useAbbreviated: true
    })
    this.reload()
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        vendorRequestDetail: { title: 'Vendor Request Details' }
      }
    }
  }
}
</script>
