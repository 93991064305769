import SimpleButton from '@/components/buttons/Simple'
import _get from 'lodash/get'
import parseAddress from '@/utilities/address/parse'
import { vendorList } from '@/services/Vendors/store'
import { vendorRequestStore } from '@/services/VendorRequests/store'
import Button from '@/components/buttons/Button'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const name = _get(entry, 'name', '')

      let serviceTypesCsv = ''
      const serviceTypes = _get(entry, 'vendorGlobalServiceTypes', null)

      if (serviceTypes) {
        Array.from(serviceTypes).forEach(element => {
          if (element) {
            if (element.vendorServiceType && element.vendorServiceType.name) {
              if (serviceTypesCsv == '') {
                serviceTypesCsv = element.vendorServiceType.name
              } else {
                serviceTypesCsv += `, ${element.vendorServiceType.name}`
              }
            }
          }
        })
      }

      return {
        name: name,
        serviceTypes: serviceTypesCsv,
        address: parseAddress({
          address: _get(entry, 'remittanceVendorGlobalAddress.address', '')
        }),
        button: {
          component: SimpleButton,
          props: {
            to: {
              name: 'vendor.vendorContacts',
              params: {
                id: _get(entry, 'vendorID', '')
              }
            },
            text: 'details'
          }
        }
      }
    })

    this.rows = rows
  },

  determineRows2: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const name = _get(entry, 'vendorName', '')
      const id = _get(entry, 'vendorRequestID', 0)
      const serviceType = _get(entry, 'vendorServiceType.name', '')

      return {
        name: name,
        serviceType,
        address: parseAddress({
          address: _get(entry, 'address', '')
        }),
        button: {
          component: Button,
          props: {
            onClick: () => {
              this.redirectToDetails(id)
            },
            text: 'Details'
          }
        },
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDeleteVendorRequest(id, name)
            },
            text: 'Delete'
          }
        },
        askquestion: {
          component: Button,
          props: {
            onClick: () => {
              this.redirectToNewConversation(entry)
            },
            text: 'Ask a Question'
          }
        }
      }
    })

    this.rows2 = rows
  },

  confirmDeleteVendorRequest(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Vendor Request',
      message: `Are you sure you want to <b>delete</b> the Vendor Request for "${name}"?`,
      confirmText: 'Delete Vendor Request',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteVendorRequest(id)
    })
  },

  async deleteVendorRequest(vendorRequestID) {
    if (vendorRequestID) {
      await vendorRequestStore.dispatch('deleteVendorRequest', {
        vendorRequestID,
        done: () => {
          this.loading = false
          this.reloadRequests()
        }
      })
    }
  },

  redirectToNewConversation(vendorrequest) {
    if (vendorrequest) {
      const vendorRequestID = _get(vendorrequest, 'vendorRequestID', 0)
      const submitterOwnerID = 1 //_get(suggestion, 'ownerID', 0)
      const vendorRequestName = _get(vendorrequest, 'vendorName', '')

      this.$router.push({
        name: 'newconversation',
        params: {
          vendorrequestid: vendorRequestID,
          vendorrequestownerid: submitterOwnerID,
          vendorrequestlabel: `Add Vendor Request - ${vendorRequestName}`
        }
      })
    }
  },

  redirectToDetails(id) {
    if (id) {
      const redirectPath = `/vendorRequests/${id}/vendorRequestDetails#tab`
      this.$router
        .push({
          path: redirectPath
        })
        .catch(() => {})
    }
  },

  async loadVendor() {
    if (this.isDebug == true) console.debug('in loadVendor()...')
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    await vendorList
      .dispatch('getVendorList', {
        hoaId: this.hoaId
      })
      .then(({ list }) => {
        this.determineRows(list)
      })

    loadingComponent.close()
  },

  async loadVendorRequests() {
    if (this.isDebug == true) console.debug('in loadVendorRequests()...')

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    await vendorRequestStore
      .dispatch('getVendorRequestList', {
        hoaID: this.hoaId
      })
      .then(({ list }) => {
        this.determineRows2(list)
      })

    loadingComponent.close()
  },

  processDetail(vendorID) {
    if (vendorID) {
      this.$router.push({
        name: 'vendor.vendorContacts',
        params: { id: vendorID }
      })
    }
  },

  createVendorRequestModal() {
    this.toggle = true
  },

  reload() {
    this.loading = true

    this.rows = []
    this.loadVendor()

    this.loading = false
  },

  reloadRequests() {
    this.loading = true

    this.rows2 = []
    this.loadVendorRequests()

    this.loading = false
  }
}
