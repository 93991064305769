var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContentLayoutOnly',{attrs:{"role":"region"}},[(_vm.vendorFilter === 'requests')?_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('vendor.title2')))]):_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('vendor.title')))]),_c('div',{staticClass:"level"},[(_vm.vendorFilter === 'requests')?_c('div',{staticClass:"level-left"},[_c('b-button',{style:(Object.assign({}, (_vm.breakpoint === 'desktop'
            ? {}
            : {
                width: '100%'
              }))),attrs:{"type":"is-primary","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.createVendorRequestModal()}}},[_vm._v(" Add Vendor Request ")])],1):_vm._e(),(_vm.vendorFilter === 'vendors')?_c('div',{staticClass:"level-left level-item pb-3",style:({ maxWidth: '80%' })},[_c('b-notification',{attrs:{"type":"is-info is-light","has-icon":"","aria-close-label":"Close notification"}},[_vm._v(" To request that a new vendor be added to the HOA, click on the Vendor Requests tab and then click 'Add Vendor Request'. To assist us in the vendor setup process, enter as much of the requested information about the vendor as possible. We will review the provided information and inform you when the new vendor is available. ")])],1):_vm._e(),_c('div',{staticClass:"level-right level-item pb-5"},[_c('b-field',{attrs:{"label":"Vendor Filter","tabindex":"0","aria-label":"vendor / vendor request filter"}},[_c('b-radio',{attrs:{"name":"name","native-value":"vendors","aria-label":"only show vendors"},model:{value:(_vm.vendorFilter),callback:function ($$v) {_vm.vendorFilter=$$v},expression:"vendorFilter"}},[_vm._v(" Vendors ")]),_c('b-radio',{attrs:{"name":"name","native-value":"requests","aria-label":"only show vendor requests"},model:{value:(_vm.vendorFilter),callback:function ($$v) {_vm.vendorFilter=$$v},expression:"vendorFilter"}},[_vm._v(" Vendor Requests ")])],1)],1)]),(_vm.vendorFilter === 'requests')?_c('span',[_c('ModernTable',{attrs:{"region":_vm.region2,"filters":_vm.filters,"rows":_vm.rows2,"columns":_vm.columns2,"pagination":{
        perPage: 12
      },"loading":_vm.loading,"headerColor":"#FFF","condensed":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{style:({ width: '100%', height: '60px' })},[_c('b-button',{class:'filter-button ' + (_vm.filters.show ? 'active' : ''),style:({ float: 'right', background: 'none', border: 0 }),attrs:{"type":"is-ghost"},on:{"click":function($event){_vm.filters.show = !_vm.filters.show}}},[_c('b-icon',{staticStyle:{"display":"inline-block"},attrs:{"pack":"mdi","icon":"filter-outline","size":"is-small"}}),_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("Filter")])],1)],1)]},proxy:true}],null,false,4268864756)})],1):_c('span',[_c('ModernTable',{attrs:{"region":_vm.region,"filters":_vm.filters,"rows":_vm.rows,"columns":_vm.columns,"pagination":{
        perPage: 12
      },"loading":_vm.loading,"headerColor":"#FFF","condensed":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{style:({ width: '100%', height: '60px' })},[_c('b-button',{class:'filter-button ' + (_vm.filters.show ? 'active' : ''),style:({ float: 'right', background: 'none', border: 0 }),attrs:{"type":"is-ghost"},on:{"click":function($event){_vm.filters.show = !_vm.filters.show}}},[_c('b-icon',{staticStyle:{"display":"inline-block"},attrs:{"pack":"mdi","icon":"filter-outline","size":"is-small"}}),_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("Filter")])],1)],1)]},proxy:true}])})],1),_c('Modal',{attrs:{"toggle":_vm.toggle},on:{"update:toggle":function($event){_vm.toggle=$event}}},[_c('VendorRequestModal')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }