import _get from 'lodash/get'
import Button from '@/components/buttons/Button'
import { vendorRequestDocumentStore } from '@/services/VendorRequestDocuments/store'
import { parseDate } from '@/utilities/Date/parse'

export const methods = {
  confirmDeleteDocument(id, description) {
    this.$buefy.dialog.confirm({
      title: 'Delete Vendor Request Document',
      message: `Are you sure you want to <b>delete</b> the vendor request document for: "${description}"?`,
      confirmText: 'Delete Vendor Request Document',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteDocument(id)
    })
  },

  async deleteDocument(id) {
    await vendorRequestDocumentStore.dispatch('deleteVendorRequestDocument', {
      vendorRequestDocumentID: id,
      done: () => {
        this.loading = false
        this.selectedDocument = null
        this.reload()
      }
    })
  },

  processDocument(document) {
    this.documentToggle = true
    this.selectedDocument = document
  },

  processDocumentUpdate(document) {
    this.toggle = true
    this.selectedDocument = document
  },

  createDocumentModal() {
    this.selectedDocument = null
    this.toggle = true
  },

  determineRows(list) {
    if (list) {
      var dateFormat = 'LL'

      this.documentsList = list.map(i => {
        const tempDate = _get(i, 'createdDate', '')
        const createdDate = parseDate(tempDate, dateFormat)

        var description = _get(i, 'description', '')
        var vendorRequestDocumentID = _get(i, 'vendorRequestDocumentID', 0)

        return {
          description: description,
          createdDate: createdDate,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processDocumentUpdate(i)
              },
              text: 'Edit'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeleteDocument(vendorRequestDocumentID, description)
              },
              text: 'Delete'
            }
          }
        }
      })
    }
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async loadDocumentList() {
    vendorRequestDocumentStore
      .dispatch('getVendorRequestDocumentList', {
        vendorRequestID: this.vendorRequestID
      })
      .then(({ list }) => {
        if (list) {
          this.determineRows(list)
        }
      })
  },

  async reload() {
    this.loading = true

    this.vendorRequestID = this.$route.params.id || 0

    await this.loadDocumentList()

    this.rows = []

    this.loading = false
  }
}
