import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  selectedVendor: null,
  toggle: false,
  vendors: [],
  vendorFilter: 'vendors',
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: true
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'name',
      label: 'Name',
      aria: 'Name',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'serviceTypes',
      label: 'Service Types',
      aria: 'Service Types',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'address',
      label: 'Address',
      aria: 'Address',
      width: '40%',
      sortable: true,
      searchable: true
    }
  ],

  region2: {},

  rows2: [],

  columns2: [
    {
      field: 'name',
      label: 'Name',
      aria: 'Name',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'serviceType',
      label: 'Service Type',
      aria: 'Service Type',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'address',
      label: 'Address',
      aria: 'Address',
      width: '40%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    dateSelector: {
      display: 'flex',
      padding: '12px',
      textAlign: 'center',
      verticalAlign: 'middle',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
