<template>
  <section
    vendor-request-attachment-modal
    :style="{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingBottom: '20px'
    }"
  >
    <div v-if="base64pdf" class="has-text-centered pb-5">
      <div>
        <div>
          <div v-dragscroll="true" :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]">
            <pdf
              style="display: none;"
              :src="base64pdf"
              @num-pages="pageCount = new Array($event)"
            />
            <!-- Actual document -->
            <pdf
              v-for="(page, index) in pageCount"
              :key="index"
              :src="`${base64pdf}`"
              :page="index + 1"
              :scale="scale"
              :resize="true"
              :style="{ margin: 'auto', width: `${100 * scale}%` }"
            />
          </div>
        </div>
        <div class="doc-controls has-text-center">
          <b-field position="is-centered">
            <div class="control">
              <b-button @click="zoom(-1)">-</b-button>
            </div>
            <div class="control">
              <b-button @click="zoom(1)">+</b-button>
            </div>
          </b-field>
        </div>
      </div>
    </div>

    <div class="pb-5" v-else-if="imageContent">
      <img :src="imageContent" />
    </div>

    <!-- Show the Upload Form -->
    <div class="pl-5 file has-text-centered">
      <b-upload v-model="file" drag-drop accept="application/pdf, image/png, image/jpeg, image/jpg">
        <section class="section">
          <div class="content has-text-centered">
            <div style="margin-left: 280px;">
              <img class="Fill-1-Copy-11" src="@/assets/images/fill-1-copy-11.svg" alt="Upload" />
            </div>
            <div v-if="file">
              {{ file.name }}
            </div>
            <div v-else>
              <div class="Drag-Drop-your-fil">
                <div class="text-style-1">Drag & Drop</div>
                <div>your files here to upload</div>
                <div>
                  Drop a PDF, PNG, JPG or JPEG file here, or click to select a file from your
                  computer
                </div>
              </div>
            </div>
          </div>
        </section>
      </b-upload>
    </div>

    <div v-if="!attachment" :style="{ overflow: 'hidden', marginTop: '24px' }">
      <b-button type="is-primary" :disabled="!file" @click="submitFile" :style="{ float: 'right' }"
        >Upload</b-button
      >
    </div>
    <div v-if="attachment" :style="{ overflow: 'hidden', marginTop: '24px' }">
      <b-button type="is-primary" @click="closeModal" :style="{ float: 'right' }">Close</b-button>
      <b-button
        type="is-primary"
        :disabled="!file"
        @click="replaceFile"
        :style="{ float: 'right', marginRight: '20px' }"
        >Replace</b-button
      >
    </div>
  </section>
</template>

<script>
/*
		import VendorRequestAttachmentModal from '@/components/Modals/VendorRequestAttachmentModal"
*/

// Components
import { mapState } from 'vuex'
import { dragscroll } from 'vue-dragscroll'
import pdf from 'vue-pdf'
import moment from 'moment'
import $ from 'jquery'
import { vendorRequestDocumentStore } from '@/services/VendorRequestDocuments/store'
import { notifyError, notifyMessage } from '@/services/notify'

export default {
  name: 'VendorRequestAttachmentModal',
  components: {
    pdf
  },

  directives: { dragscroll },

  props: {
    vendorRequestID: Number,
    attachment: Object
  },

  data: function() {
    return {
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: false,
      file: null,
      base64pdf: '',
      scale: 1,
      searchTerm: -1,
      imageContent: null,
      pageCount: []
    }
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    reloadDocuments() {
      this.reload()
    }
  },

  methods: {
    async reload() {
      if (
        this.isDebug == true &&
        this.attachment &&
        this.attachment.vendorRequestDocumentID != undefined
      )
        console.debug('reload attachment=' + this.attachment.vendorRequestDocumentID)

      if (
        this.attachment &&
        this.attachment.vendorRequestDocumentID &&
        this.attachment.vendorRequestDocumentID > 0
      ) {
        await vendorRequestDocumentStore
          .dispatch('getVendorRequestDocumentById', {
            vendorRequestDocumentID: this.attachment.vendorRequestDocumentID
          })
          .then(async () => {
            if (this.attachment && this.attachment.documentID && this.attachment.documentID > 0) {
              if (this.isDebug == true)
                console.debug('attachment.documentID=' + this.attachment.documentID)
              await this.loadCurrentDocument()
            }
          })
      }
    },

    async loadCurrentDocument() {
      console.debug('in loadCurrentDocument...' + JSON.stringify(this.attachment))
      if (
        !this.attachment ||
        this.attachment === undefined ||
        !this.attachment.vendorRequestDocumentID ||
        this.attachment.vendorRequestDocumentID === undefined ||
        this.attachment.description === undefined
      ) {
        return
      }

      this.searchTerm = this.attachment.description.indexOf('.pdf')

      try {
        const params = {
          vendorRequestDocumentID: this.attachment.vendorRequestDocumentID,
          asBase64: true
        }

        await vendorRequestDocumentStore
          .dispatch('getVendorRequestDocumentGetFile', {
            params
          })
          .then(async ({ returned }) => {
            if (this.attachment && this.attachment.documentID && this.attachment.documentID > 0) {
              if (this.isDebug == true)
                console.debug('attachment.documentID=' + this.attachment.documentID)
              if (this.isDebug == true)
                console.debug(
                  'params' + JSON.stringify(params) + ' - returned=' + JSON.stringify(returned)
                )
              if (returned && returned != undefined) {
                if (this.isDebug == true)
                  console.debug(
                    'image or pdf............this.searchTerm:' +
                      this.searchTerm +
                      '............' +
                      returned
                  )

                if (this.searchTerm != -1) {
                  this.base64pdf = returned
                  this.imageContent = null
                } else {
                  this.imageContent = returned
                  this.base64pdf = null
                }
              }
            }
          })
      } catch (e) {
        if (this.isDebug == true) console.debug(e)
      }
    },

    closeModal() {
      this.$emit('update:toggle', (this.toggle = !this.toggle))

      // auto-close modal
      $('#clickclose').click()
    },

    async replaceFile() {
      if (
        !this.attachment ||
        this.attachment === undefined ||
        !this.attachment.vendorRequestDocumentID ||
        this.attachment.vendorRequestDocumentID === undefined ||
        this.attachment.description === undefined
      ) {
        return
      }

      if (!this.file || this.file == undefined) {
        notifyError('The upload file is a required.')
        return
      }

      console.debug(this.file.name + ', in replace file...' + JSON.stringify(this.attachment))

      this.loading = true

      try {
        if (this.attachment) {
          const payload = {
            vendorRequestDocumentID: this.attachment.vendorRequestDocumentID,
            description: this.file.name || '',
            file: this.file
          }

          await vendorRequestDocumentStore
            .dispatch('updateVendorRequestDocumentAttachment', payload)
            .then(({ returned }) => {
              console.debug('returned replace=' + JSON.stringify(returned))

              if (
                returned &&
                returned != undefined &&
                returned.documentId != undefined &&
                returned.documentId > 0
              ) {
                this.imageContent = null
                this.base64pdf = null
                this.attachment.documentId = returned.documentID
                this.loadCurrentDocument()
                notifyMessage('Your attachment has been successfully replaced.')
              }
            })
        }
      } catch (e) {
        console.debug(e)
      }
      this.loading = false
    },

    async submitFile() {
      if (this.vendorRequestID <= 0) {
        notifyError('There was a problem uploading this document.')
        return
      }

      try {
        if (!this.attachment) {
          const payload = {
            file: this.file,
            description: this.file.name || '',
            hoaID: this.hoaId
          }

          console.debug('file name=' + payload.description)

          let documentID = 0
          await vendorRequestDocumentStore
            .dispatch('uploadVendorRequestDocument', {
              payload: payload
            })
            .then(({ returnedfile }) => {
              console.debug('in uploadVendorRequestDocument=' + JSON.stringify(returnedfile))
              this.base64pdf = ''
              if (returnedfile && returnedfile !== undefined) {
                documentID = returnedfile.documentId
              } else {
                console.debug('There was a problem uploading your attachments.')
                return
              }
            })

          if (documentID > 0) {
            await vendorRequestDocumentStore
              .dispatch('addVendorRequestDocument', {
                vendorRequestID: this.vendorRequestID,
                documentID,
                description: payload.description
              })
              .then(async ({ returned }) => {
                if (returned && returned != undefined && returned.vendorRequestDocumentID > 0) {
                  setTimeout(() => {
                    this.closeModal()
                  }, 2000)

                  notifyMessage(`Successfully uploaded the document.`)
                }
              })
          }
        }
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    },

    zoom(direction) {
      const amt = Math.sqrt(2)
      if (direction > 0) {
        this.scale *= amt
      } else {
        this.scale /= amt
      }

      // constrain to min/max
      this.scale = Math.max(0.5, Math.min(4, this.scale))
    }
  }
}
</script>

<style lang="scss">
.modal-h-full {
  left: 50%;
  transform: translate(-50%);
}

#request-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}

.doc-viewer {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 130%;
  min-height: 40vh;
  max-height: calc(100vh - 320px);
  border: 1px solid #dbdbdb;

  &.zoom-active {
    cursor: grab;
  }
}

.doc-controls {
  margin-top: -1em;
}

.uploader {
  .rectangle {
    width: 244px;
    height: 137px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0 0px;
    border-radius: 4px;
  }
  .Drag-Drop-your-fil {
    width: 192px;
    height: 34px;
    margin: 7px 0 8px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #838383;
  }

  .Drag-Drop-your-fil .text-style-1 {
    font-weight: bold;
  }

  img.Fill-1-Copy-11 {
    width: 24px;
    height: 30px;
    margin: 0 85px 7px 83px;
    object-fit: contain;
  }
}
</style>
