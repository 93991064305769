export const methods = {
  async reload() {
    this.loading = true

    this.vendorRequestID = this.$route.params.id

    console.debug('vendorRequestID=' + this.vendorRequestID)

    this.loading = false
  },

  returnToParent() {
    this.$router.push({
      name: 'vendorRequests'
    })
  }
}
