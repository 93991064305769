/*
  import { vendorRequestDocumentStore } from '@/services/VendorRequestDocuments/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyMessage, notifyError } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getVendorRequestDocumentList({}, { vendorRequestID }) {
    console.log('in getVendorRequestDocumentList...')
    try {
      const result = await kms.get('/VendorRequests/VendorRequestDocument/List', {
        params: {
          vendorRequestID
        }
      })

      if (isDebug == true)
        console.debug('getVendorRequestDocumentList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The vendor request documents list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getVendorRequestDocumentById({}, { vendorRequestDocumentID }) {
    try {
      console.debug('in getVendorRequestDocumentById...' + vendorRequestDocumentID)
      const vendorRequestDocument = await kms.get(
        `/VendorRequests/VendorRequestDocument/${vendorRequestDocumentID}`
      )

      if (isDebug == true)
        console.debug('getVendorRequestDocumentById=' + JSON.stringify(vendorRequestDocument))

      return {
        result: vendorRequestDocument
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the vendor request document.`)
      console.error(exception)
    }

    return {
      vendor: {}
    }
  },

  async addVendorRequestDocument({}, { vendorRequestID, documentID, description }) {
    try {
      const result = await kms.post(`/VendorRequests/VendorRequestDocument`, {
        vendorRequestID,
        documentID,
        description
      })

      if (isDebug == true) console.debug('addVendorRequestDocument=' + JSON.stringify(result))

      return {
        returned: result
      }
    } catch (exception) {
      notifyProblem('There was a problem adding the vendor request document.')
      console.error(exception)
    }

    return {
      returned: null
    }
  },

  async uploadVendorRequestDocument({}, { payload }) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/VendorRequests/VendorRequestDocument/StoreDocument?hoaID=${payload.hoaID}&vendorRequestDocumentID=0&description=${payload.description}`
      const uploadedFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug('uploadVendorRequestDocument with payload=' + JSON.stringify(uploadedFile))

      return {
        returnedfile: uploadedFile
      }
    } catch (exception) {
      notifyProblem('There was a problem uploading the vendor request photo.')
      console.error(exception)
    }

    return {
      returnedfile: null
    }
  },

  async updateVendorRequestDocumentAttachment({}, payload = {}) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      console.debug('in updateVendorRequestDocumentAttachment...' + JSON.stringify(payload))
      const path = `/VendorRequests/VendorRequestDocument/UpdateAttachment?vendorRequestDocumentID=${payload.vendorRequestDocumentID}&description=${payload.description}`

      const updateVendorRequestDocument = await kms.put(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (this.isDebug == true)
        console.debug('results=' + JSON.stringify(updateVendorRequestDocument))

      return {
        returned: updateVendorRequestDocument
      }
    } catch (exception) {
      if (exception.message.indexOf('same file extension') > -1) {
        notifyError(exception.message)
      } else {
        notifyProblem('There was a problem updating this vendor request document attachment.')
        console.error(exception)
      }
    }

    return {
      returned: null
    }
  },

  async deleteVendorRequestDocument({ dispatch }, { vendorRequestDocumentID, done }) {
    try {
      const path = `/VendorRequests/VendorRequestDocument/${vendorRequestDocumentID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the vendor request document.`)
        done()
      } else {
        notifyError('There was a problem deleting the vendor request document.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting the vendor request document.`)
      console.error(exception)
    }
  },

  async getVendorRequestDocumentGetFile({}, { params = {} }) {
    try {
      const result = await kms.get(`/VendorRequests/VendorRequestDocument/GetFile`, {
        params
      })

      if (isDebug == true) console.debug('getVendorRequestDocumentGetFile=' + result)

      return {
        returned: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the vendor request document file.`)
      console.error(exception)
    }

    return {
      returned: null
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const vendorRequestDocumentStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
